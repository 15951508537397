import React from 'react';
import { Head } from '@inertiajs/react';
import Footer from '@/Components/Footer.jsx';

export default function NotFoundPage() {
  return (
    <div className="bg-indigo-300">
      <Head title="LinkBlend" />
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center text-center space-y-8">
          <img src="/images/404-page.png" className="w-2/3 rounded" alt="404 image" />
          <h1 className="text-2xl sm:text-4xl font-extrabold text-gray-50">Ooops! Something went wrong!</h1>
          <p className="text-gray-50 sm:text-lg font-medium">
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
          </p>
          <a href="/" className="bg-gray-700 text-gray-100 cursor-pointer text-lg px-8 py-3 rounded-full transform transition hover:bg-gray-600">
            Back to Home
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
